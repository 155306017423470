import { extendClassName } from "utils/common";
import PaddedMaxWidthContainer from "components/layout/PaddedMaxWidthContainer";
import { ReactNode } from "react";

interface IColourPageSectionProps {
  id?: string;
  colour?: string;
  colourGradient?: string;
  noPadding?: boolean;
  onClick?: () => void;
  additionalClassName?: string;
  children?: ReactNode;
}

const ColourPageSection = ({
  id,
  colour = "#C3FBF1",
  colourGradient = "#90F6DF linear-gradient(#C3FBF1, #6CFADF)",
  noPadding,
  onClick,
  additionalClassName,
  children,
}: IColourPageSectionProps) => (
  <div id={id} className="colour fullWidth flexCentered" onClick={onClick}>
    {noPadding ? (
      <div
        className={extendClassName(
          "colourPageSection fullWidth flexCentered",
          additionalClassName
        )}
      >
        {children}
      </div>
    ) : (
      <PaddedMaxWidthContainer
        additionalClassName={extendClassName(
          "colourPageSection",
          additionalClassName
        )}
      >
        {children}
      </PaddedMaxWidthContainer>
    )}

    <style jsx>
      {`
        .colour {
          background: ${colourGradient};
          isolation: isolate;
        }

        :global(.colourPageSection) {
          text-align: center;
          padding: 4em 0;
        }

        :global(.colourPageSection .subheading:not(.overrideColourScheme)) {
          text-shadow: 0.07em 0.07em white;
        }

        :global(
            .colourPageSection .subheading:not(.overrideColourScheme) strong
          ) {
          -webkit-text-fill-color: ${colour};
          text-shadow: 0.08em 0.08em white;
        }

        :global(
            .colourPageSection .subheading:not(.overrideColourScheme) .word
          ) {
          background-image: none;
        }
      `}
    </style>
  </div>
);

export default ColourPageSection;
