import PageIndicator from 'components/PageIndicator';
import React, { ReactNode, useRef, useState } from 'react';
import $ from 'jquery';
import { ASSET_PREFIX } from 'utils/constants';
import { useEffect } from 'react';
import { sleep } from 'utils/common';

interface ICardListProps {
  id: string;
  darkOnLight?: boolean;
  children?: ReactNode[];
  withScrollButtons?: boolean;
  arrowStyle?: string | number;
  noDots?: boolean;
  fastScroll?: boolean;
  style?: React.CSSProperties;
}

const CardList = ({
  id,
  darkOnLight = true,
  children,
  withScrollButtons = true,
  arrowStyle = 1,
  noDots = false,
  fastScroll = false,
  style = {},
}: ICardListProps) => {
  const cardListRef = useRef<HTMLDivElement>(null);

  const numChildren = children?.length || 0;

  // Gets rid of `null` or `false` children which is important for counting for PageIndicator.
  if (Array.isArray(children)) {
    children = children.filter((child) => child);
  }

  const GAP_PX = 12;
  const [scrollPx, setScrollPx] = useState(0);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);

  const [showLeftScrollButton, setShowLeftScrollButton] = useState(false); // Initially we are scrolled to left fully.
  const [showRightScrollButton, setShowRightScrollButton] = useState(true);

  const [overflowing, setOverflowing] = useState(false);

  const scrollGallery = (dx: number) => {
    console.log('scrollGallery dx', dx);
    if (!cardListRef.current) {
      return;
    }
    // cardListRef.current?.animate({ scrollLeft: dx }, 300);

    cardListRef.current.scrollTo({
      left: cardListRef.current.scrollLeft + dx,
      behavior: 'smooth',
    });
    // if (typeof window !== 'undefined') {
    //   $(`#${id}`).animate({ scrollLeft: dx }, 300);
    // }
  };

  let scrollWidth =
    (cardListRef.current?.scrollWidth || 0) -
    (cardListRef.current?.clientWidth || 0);

  const updateOverflowing = () => {
    if (!cardListRef.current) {
      return;
    }
    setOverflowing(
      (cardListRef.current.scrollWidth || 0) -
        (cardListRef.current.clientWidth || 0) >
        61
    );
  };

  useEffect(() => {
    window?.addEventListener('resize', updateOverflowing);

    updateOverflowing();

    return () => {
      window?.removeEventListener('resize', updateOverflowing);
    };
  }, []);

  useEffect(() => {
    setScrollPx(GAP_PX + (cardListRef.current?.clientWidth || 0));
    sleep(200).then(updateOverflowing);
    setLoading(false);
  }, [children, cardListRef.current?.clientWidth]);

  return (
    <>
      <div
        className="flexCentered fullWidth"
        style={{ position: 'relative' }}
      >
        {withScrollButtons && showLeftScrollButton && overflowing && (
          <img
            src={`${ASSET_PREFIX}/images/common/arrow-left-style${arrowStyle}.svg`}
            className="scrollButton scalable"
            onClick={() => scrollGallery(-(fastScroll ? 1000 : scrollPx))}
            style={{
              left: 0,
              zIndex: 3,
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            alt="Left arrow"
          />
        )}

        <div
          id={id}
          ref={cardListRef}
          className="cardList"
          onScroll={(e) => {
            if (!cardListRef.current) {
              return;
            }
            setShowLeftScrollButton(cardListRef.current.scrollLeft > 25);
            setShowRightScrollButton(
              scrollWidth - cardListRef.current.scrollLeft > 75
            );

            setPage(
              Math.round(
                (cardListRef.current.scrollLeft * (numChildren - 1)) /
                  scrollWidth
              )
            );
          }}
          style={{ ...style }}
        >
          <img
            src="https://img.spacergif.org/v1/spacer.gif"
            width="1em"
            height="1"
            style={{ width: '1em' }}
            alt=""
          />

          {children}

          <img
            src="https://img.spacergif.org/v1/spacer.gif"
            width="1em"
            height="1"
            style={{ width: '1em' }}
            alt=""
          />
        </div>

        {withScrollButtons && showRightScrollButton && overflowing && (
          <img
            src={`${ASSET_PREFIX}/images/common/arrow-right-style${arrowStyle}.svg`}
            className="scrollButton scalable"
            onClick={() => scrollGallery(fastScroll ? 1000 : scrollPx)}
            style={{
              right: 0,
              zIndex: 3,
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            alt="Right arrow"
          />
        )}
      </div>

      {overflowing && !noDots && (
        <PageIndicator
          currentPageIndex={page}
          pageCount={numChildren}
          darkOnLight={darkOnLight}
          style={{
            marginTop: '1em',
          }}
        />
      )}

      <style jsx>{`
        .cardList {
          width: 100%;
          max-width: ${380 * numChildren}px;
          display: flex;
          justify-content: ${overflowing ? 'flex-start' : 'center'};
          overflow-x: auto;
          overflow-y: hidden;
          padding: 0 2.5em 0.5em;
          opacity: ${loading ? 0 : 1};
        }

        .scrollButton {
          width: ${scrollPx * 0.13}px;
          min-width: 40px;
          max-width: 70px;
        }

        :global(.cardList > div) {
          flex-shrink: 0;
          margin-right: ${GAP_PX}px;
        }

        :global(.cardList > div:last-of-type) {
          margin-right: 0;
        }

        .cardList::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </>
  );
};

export default CardList;
