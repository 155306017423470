// @ts-nocheck
import ColourPageSection from 'components/ColourPageSection';
import React, { ReactNode } from 'react';

interface IGreenPageSection {
  id?: string
  noPadding?: boolean
  onClick?: () => void
  additionalClassName?: string
  children?: ReactNode
}


const GreenPageSection = ({
  id,
  noPadding,
  onClick,
  additionalClassName,
  children,
}: IGreenPageSection) => (
  <div
    id={id}
    className="greenPageSectionBackground fullWidth flexCentered"
    onClick={onClick}
  >
    <ColourPageSection
      colour="#C3FBF1"
      colourGradient="#90F6DF linear-gradient(#C3FBF1, #6CFADF)"
      noPadding={noPadding}
      additionalClassName={`greenPageSection ${additionalClassName}`}
    >
      {children}
    </ColourPageSection>
  </div>
);

export default GreenPageSection;
