import { colors } from "utils/constants";

const PageIndicator = ({ currentPageIndex, onDotClick = () => {}, pageCount, darkOnLight = false, style }) => (
  <div className='pageDotsContainer' style={style}>
    { Array(pageCount).fill().map((_, i) =>
      <div
        key={i}
        className={`pageDot${i === currentPageIndex ? ' selected' : ''}`}
        onClick={() => onDotClick(i)}
      />
    )}

    <style jsx>{`
      .pageDotsContainer {
        display: flex;
        justify-content: center;
      }
      
      .pageDot {
        background-color: transparent;
        height: 8px;
        width: 8px;
        margin: 0.3em;
        border-radius: 4px;
        border: 1.5px solid ${darkOnLight ? colors.primaryBrandGray : 'white'};
        transition: 0.1s;
      }
      
      .pageDot.selected {
        transform: scale(1.2);
        transition: 0.1s;
        background-color: ${darkOnLight ? colors.primaryBrandGray: 'white'};
      }
    `}</style>
  </div>
);

export default PageIndicator;
