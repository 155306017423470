// @ts-nocheck
import { ASSET_PREFIX, SubscriptionType } from 'utils/constants';
import { extendClassName } from 'utils/common';
import CardList from 'components/CardList';
import Chevron from 'components/Chevron';
import React from 'react';

export const HowItWorksStepConstants = {
  GENERIC: [
    {
      heading: '1. Pick numbers',
      description:
        'Choose from our paid or free weekly membership options and then select 5 lucky numbers',
      icon: `${ASSET_PREFIX}/images/how-it-works/cursor-icon.svg`,
    },
    {
      heading: '2. Match to win',
      description:
        'The winning numbers will be randomly drawn every Tuesday & Friday at 5pm',
      icon: `${ASSET_PREFIX}/images/how-it-works/bingo-cage-icon.svg`,
    },
    {
      heading: '3. Choose your prize',
      description:
        'The more numbers you match, the bigger the prize you can choose – over 200 epic prizes on offer',
      icon: `${ASSET_PREFIX}/images/how-it-works/choose-your-prize-icon.svg`,
    },
  ],
  forSubscriptionType: (subscriptionType) => {
    if (!subscriptionType) {
      return HowItWorksStepConstants.GENERIC;
    }

    return [
      {
        heading: '1. Pick numbers',
        description: 'Pick 5 numbers to be entered into our weekly draws',
        icon: `${ASSET_PREFIX}/images/how-it-works/cursor-icon.svg`,
      },
      {
        heading: '2. Match to win',
        description: `The winning numbers will be randomly drawn every ${
          subscriptionType === SubscriptionType.FREE
            ? 'Friday'
            : 'Tuesday & Friday'
        } at 5pm`,
        icon: `${ASSET_PREFIX}/images/how-it-works/bingo-cage-icon.svg`,
      },
      {
        heading: '3. Choose your prize',
        description:
          subscriptionType === SubscriptionType.FREE
            ? 'Matching 5 numbers allows you to choose any of our 10+ epic £2,000 prizes'
            : `The more numbers you match, the bigger the prize you can choose – over ${
                subscriptionType === SubscriptionType.LITE ? '180' : '200'
              } epic prizes on offer`,
        icon: `${ASSET_PREFIX}/images/how-it-works/choose-your-prize-icon.svg`,
      },
    ];
  },
};

const Step = ({ icon, heading, description }) => {
  return (
    <div key={heading} className="step">
      <img src={icon} className="stepIcon" alt="" />

      <p className="large xbold" style={{ marginBottom: '0.5rem' }}>
        {heading}
      </p>

      <p className="medium">{description}</p>

      <style jsx>
        {`
          .step {
            max-width: 280px;
            padding: 1em;
            flex: 1;
            margin: 0.5em 0;
          }

          .stepIcon {
            height: 28px;
            margin-bottom: 0.75em;
          }

          p.heading {
            font-size: 1.25em;
            font-weight: 800;
            margin: 0.25em 0;
          }

          p.description {
            font-weight: 600;
          }

          @media (min-width: 700px) {
            .step {
              max-width: unset;
              margin: 0;
            }

            .stepIcon {
              height: 38px;
            }
          }
        `}
      </style>
    </div>
  );
};

interface IHowItWorksStepsProps {
  subscriptionType?: keyof HowItWorksStepConstants
  horizontalScrollOnMobile?: boolean
  keepMobileLayoutOnDesktop?: boolean
}

const HowItWorksSteps = ({
  subscriptionType,
  horizontalScrollOnMobile,
  keepMobileLayoutOnDesktop = false,
}: IHowItWorksStepsProps) => {
  const getSteps = () =>
    HowItWorksStepConstants.forSubscriptionType(subscriptionType).map(
      (step, idx) => (
        <React.Fragment key={`step-${idx}`}>
          {idx > 0 && (
            <div className="arrow flexCentered desktopOnly">
              <Chevron
                direction="right"
                style={{
                  justifyContent: "center",
                  fontSize: "2em",
                  marginLeft: "0.25rem",
                }}
              />
            </div>
          )}
          <Step
            icon={step.icon}
            heading={step.heading}
            description={step.description}
          />
        </React.Fragment>
      )
    );

  return (
    <div
      className={extendClassName('howItWorksSteps flexCentered', [
        horizontalScrollOnMobile && 'horizontalScrollOnMobile',
        keepMobileLayoutOnDesktop && 'keepMobileLayoutOnDesktop',
      ])}
    >
      {horizontalScrollOnMobile ? (
        <CardList id="how-it-works-card-list" withScrollButtons={false}>
          {getSteps()}
        </CardList>
      ) : (
        <>{getSteps()}</>
      )}

      <style jsx>
        {`
        .howItWorksSteps {
          text-align: center;
          margin: 1em auto;
          width: 100%;
        }
        
        .howItWorksSteps:not(.horizontalScrollOnMobile) {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1em 0;
          justify-items: center;
        }
        
        :global(.howItWorksSteps #how-it-works-card-list) {
          width: 100%;
          max-width: 400px;
        }
        
        :global(.howItWorksSteps #how-it-works-card-list .step) {
          width: 100%;
          flex: unset;
          flex-shrink: 0;
          justify-content: flex-start;
        }
        
        :global(.howItWorksSteps .arrow.desktopOnly) {
          display: none;
        }
        
        @media(min-width: 700px) {
          .howItWorksSteps:not(.horizontalScrollOnMobile, .keepMobileLayoutOnDesktop) {
            display: flex;
            flex-direction: row;
            align-items: stretch;
          }
          
          :global(.howItWorksSteps:not(.keepMobileLayoutOnDesktop) .arrow.desktopOnly) {
            display: flex;
            align-self: stretch;
            margin: 0 0.5em;
          }
          
          :global(.howItWorksSteps #how-it-works-card-list) {
            max-width: unset;
            padding: 0;
          }
          
          :global(.howItWorksSteps #how-it-works-card-list .step) {
            flex-shrink: 1;
          }
      `}
      </style>
    </div>
  );
};

export default HowItWorksSteps;
